<template lang="html">
  <div class="TextQuestion">
    <div class="TextQuestion--InputWrapper" :class="{'disabled':notResponse}">
      <i class="fas fa-pencil-alt"></i>
      <input
        type="text"
        class="TextQuestion--Input"
        :name="'answer_'+question.question.order"
        :disabled="notResponse"
        v-model="answer"
        v-on:keyup="addAnswer($event)"
        :placeholder="$parent.translationMethod('Imput_textanswer')">
    </div>
      <div class="TextQuestion__notResponse" v-if="question.question.notResponse">
        <input type="checkbox"
          :id="'answer_'+question.question.order"
          :name="'answer_'+question.question.order"
          v-on:change="addAnswer()"
          v-model="notResponse">
        <label :for="'answer_'+question.question.order">
          <span>
          </span>
          <i class="fa fa-check"></i>
          {{question.question.notResponseText}}
        </label>
      </div>
  </div>
</template>

<script>
export default {
  name: 'TextQuestion',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answer:null,
      notResponse:false,
    }
  },
  methods:{
    addAnswer(e){
      this.answer = e != undefined ? e.target.value : null
      let answerValid = false
      let answer = {}

      if (this.answer != null && this.answer.length > 0 && (/\S/.test(this.answer))) {
        answer.text = this.answer
        answerValid = true
      }
      if (this.notResponse) {
        answer.text = this.question.question.notResponseText
        answer.nsnc = true
        answerValid = true
      }
      if (this.question.question.textEmpty && (this.answer == null || this.answer == "")) {
        answer.text = ""
        answerValid = true
      }
      if (this.question.question.textEmpty){
        answerValid = true
      }
      this.$emit('send-answers', {answer:answer,question:this.question, answerValid:answerValid})
    },
  },
  async mounted(){
    this.addAnswer()
    if (this.question.answer) {
      this.answer = this.question.answer.text
      if (this.answer == this.question.question.notResponseText) this.notResponse = true
      this.addAnswer()
    }
    if(this.question.question.textEmpty){
      this.$emit('send-answers', {answer:{text:""},question:this.question, answerValid:true})
    }
  },
}
</script>

<style lang="scss">
.TextQuestion{
  &--InputWrapper{
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border:1px solid #dfdfdf;
    color: #a6a6a6;
    &.disabled{
      background: #d3d3d3;
      cursor: not-allowed;
      >input{
        cursor: not-allowed;
        background: #d3d3d3;
      }
    }
    i{
      padding:10px;
      font-size: 25px;
    }
  }
  &--Input{
    width: 100%;
    height:100%;
    border-radius: 5px;
    border:none;
    outline:none;
    background-color: white;
    &::placeholder{
      color: #cdcdcd;
    }
  }
  &__notResponse{
    width: 100%;
    display: flex;
    input {
      display: none;
      &:checked + label{
        color: var(--main-color);
        font-weight: 700;
        span{
          display:none;
        }
        i{
          display:block;
          animation: fadeIn 0.2s;
        }
      }
    }
    label{
      display: flex;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      i{
        display: none;
        font-size:25px;
        height: 26px;
        margin-right: 8px;
        background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      span{
        display: flex;
        width: 20px;
        height: 20px;
        margin: 3px 10px 3px 3px;
        padding:4px;
        vertical-align: middle;
        border: 1px solid #aeaeae;
        border-radius: 5px;
      }
    }
  }
}
</style>
