<template lang="html">
  <div class="Question" :class="{'IE11':isIE11()}">
    <div class="false-blur" v-if="isIE11()">
    </div>
    <div class="Question__Header">
      <span class="Question__Header--Num" v-if="currentQuestionOrder < 10">0{{currentQuestionOrder}}</span>
      <span class="Question__Header--Num" v-if="currentQuestionOrder > 9">{{currentQuestionOrder}}</span>
      <p v-html="question.question.text" :class="{'default':(parseInt(currentQuestionTimer, 10) == question.question.id) && (question.question.timerValue != null && question.question.timerValue != '' && question.question.timerValue > 0)}"></p>
      <Timer :class="{'hidden-timer':isTimerInvisible}"
        class="Question__Header--Timer"
        v-if="(parseInt(currentQuestionTimer, 10) == question.question.id) && (question.question.timerValue != null && question.question.timerValue != '' && question.question.timerValue > 0)"
        :value="question.question.timerValue"
        :stop="stopTimer"
        v-on:set-timer-finished="setTimerFinished(question.question.timerOption)"
      ></Timer>
      <img class="sortingDragImage" v-if="question.question.type == 'SORTING'" src="../../assets/img/execution/drag.png" alt="">
    </div>
    <p class="Question--Description" v-if="question.question.description != undefined && !groupedQuestion" v-html="question.question.description"></p>
    <SilentboxGroup ref="group" class="MediaGroup" v-show="imageDisplayType == 'IMAGE_DISPLAY_MINIATURE' && question.question.type != 'HEATMAP'">
      <SilentboxGroupItem
        class="MediaItem MediaItem--Image"
        v-for="(image, index) in images"
        :key="image.id != null ? image.id : $options.filters.mediaId(index)"
        :src="image.src"
        :style="{ backgroundImage: 'url(' + encodeImageURL(image.src) + ')'}">
        <div class="MediaItem__Overlay">
          <Icon class="MediaItem__TypeIcon" name="picture-o" />
        </div>
      </SilentboxGroupItem>
    </SilentboxGroup>
    <div class="Activity--MediaWrapper" v-show="videos">
      <MediaViewer @videoFinished="handleVideoFinished" class="activity__Media--Videos" :allowTitle="true" :videos="videos" />
    </div>
    <div class="Question__ImagesMin"  v-show="imageDisplayType == 'IMAGE_DISPLAY_BIG_MINIATURE' && question.question.type != 'HEATMAP'">
      <div class="Question__ImagesMin__Image" v-for="(img,i) in images" v-on:click="openImageOverlay(img, i)">
        <img :src="img.src" alt="">
        <div class="Question__ImagesMin__Flip">
          <Icon class="Question__ImagesMin__Icon" name="picture-o" />
        </div>
      </div>
      <silentbox-overlay></silentbox-overlay>
    </div>
    <div class="Question__ImagesCr" :class="{'Question__ImagesCr--MinImages':images.length == 1}" v-if="imageDisplayType == 'IMAGE_DISPLAY_CAROUSEL' && question.question.type != 'HEATMAP'">
        <swiper
       :slides-per-view="1"
       :space-between="50"
       :navigation="images.length > 1"
       :pagination="{ clickable: true }"
       :scrollbar="{ draggable: true }"
       :zoom="true">
       <swiper-slide v-for="(img, i) in images" v-bind:key="i">
         <div class="swiper-zoom-container">
           <img :src="img.src">
         </div>
       </swiper-slide>
      </swiper>
    </div>
    <div class="Question__ImagesBig" v-show="imageDisplayType == 'IMAGE_DISPLAY_BIG' && question.question.type != 'HEATMAP'">
      <div class="Question__ImagesBig__Image" v-for="(img,i) in images" v-on:click="openImageOverlay(img, i)">
        <img :src="img.src">
        <div class="Question__ImagesBig__Flip">
          <Icon class="Question__ImagesBig__Icon" name="picture-o" />
        </div>
      </div>
      <silentbox-overlay></silentbox-overlay>
    </div>
    <div class="Question__ImagesMax" v-show="imageDisplayType == 'IMAGE_DISPLAY_MAX_SIZE' && question.question.type != 'HEATMAP'">
      <div class="Question__ImagesMax__Image" :id="i" v-for="(img,i) in images" v-on:click="openImageMaxSize(img, i)">
        <img :src="img.src">
        <div class="Question__ImagesMax__Flip">
          <Icon class="Question__ImagesMax__Icon" name="picture-o" />
        </div>
      </div>
    </div>
    <div class="Question__ImageMaxSizeViewer" v-show="maxSizeImage.visible && question.question.type != 'HEATMAP'">
      <div class="Question__ImageMaxSizeViewer--content">
        <div class="Question__ImageMaxSizeViewer--close" v-on:click="maxSizeImage.visible = false">
          <p> </p>
          <p> </p>
        </div>
        <button type="button" class="button-close" v-on:click="maxSizeImage.visible = false">{{$t('close_image')}}</button>
        <div style="width: 100%;height:100%;">
          <swiper
         :slides-per-view="1"
         :space-between="50"
         :scrollbar="{ draggable: true }"
         :zoom="true">
         <swiper-slide>
           <div class="swiper-zoom-container">
             <img :src="maxSizeImage.img">
           </div>
         </swiper-slide>
        </swiper>
        </div>
      </div>
    </div>


    <div class="Question__Type">
      <MultiSelection :question="question" v-if="(question.question.type == 'MULTISELECTION' && !groupedQuestion) || (question.question.type == 'INTERNAL_VARIABLE' && question.question.secondaryType == 'MULTISELECTION')" v-on:send-answers="handleAnswer($event)"/>
      <Selection :question="question" v-if="(question.question.type == 'SELECTION') || (question.question.type == 'INTERNAL_VARIABLE' && question.question.secondaryType == 'SELECTION')" v-on:send-answers="handleAnswer($event)"/>
      <Ranking :question="question" v-if="question.question.type == 'RANKING'" v-on:send-answers="handleAnswer($event)" />
      <RatinFaces :question="question" v-if="question.question.type == 'RATING_FACES'" v-on:send-answers="handleAnswer($event)" />
      <ImageChoice :question="question" v-if="question.question.type == 'IMAGE_CHOICE'" v-on:send-answers="handleAnswer($event)" />
      <MultipleImageChoice :question="question" v-if="question.question.type == 'MULTIPLEIMAGE_CHOICE'" v-on:send-answers="handleAnswer($event)" />
      <Sorting :question="question" v-if="question.question.type == 'SORTING'" v-on:send-answers="handleAnswer($event)" />
      <TextQuestion :question="question" v-if="question.question.type == 'TEXT'" v-on:send-answers="handleAnswer($event)" />
      <MultiText :question="question" v-if="question.question.type == 'MULTITEXT'" v-on:send-answers="handleAnswer($event)" />
      <Textarea :question="question" v-if="question.question.type == 'TEXTAREA'" v-on:send-answers="handleAnswer($event)" />
      <Number :question="question" v-if="question.question.type == 'NUMBER'" v-on:send-answers="handleAnswer($event)" />
      <DecimalNumber :question="question" v-if="question.question.type == 'DECIMAL_NUMBER'" v-on:send-answers="handleAnswer($event)" />
      <MultiNumber :question="question" v-if="question.question.type == 'MULTINUMBER'" v-on:send-answers="handleAnswer($event)" />
      <Date :question="question" v-if="question.question.type == 'DATE'" v-on:send-answers="handleAnswer($event)" />
      <MatrixRefactor ref="Matrix" v-if="question.question.type == 'MATRIX'" :question="question" v-on:send-answers="handleAnswer($event)" @scrollToTopOfQuestion = "scrollToTopOfQuestion()"/>
      <ImageUrl :question="question" v-if="question.question.type == 'IMAGE_URL'" v-on:send-answers="handleAnswer($event)" />
      <DividePoints :question="question" v-if="question.question.type == 'DIVIDE_POINTS'" v-on:send-answers="handleAnswer($event)" />
      <Label :question="question" v-if="question.question.type == 'LABEL'" v-on:send-answers="handleAnswer($event)" />
      <Maxdiff :question="question" v-if="question.question.type == 'MAXDIFF'" v-on:send-answers="handleAnswerMaxdiff($event)" />
      <GroupedQuestions :question="question" v-if="groupedQuestion" v-on:send-answers="handleAnswer($event);saveAnswer($event)"/>
      <HeatMapQuestion :question="question" v-if="question.question.type == 'HEATMAP'" v-on:send-answers="handleAnswer($event)" />
    </div>
    <div class="Question__Error" v-if="error">
      <p>{{errorText}}</p>
    </div>
    <div class="Question__Actions" v-if="question.question.type != 'MAXDIFF' && !groupedQuestion">
      <button type="button" name="button_previous" class="Question__Actions--Button previous" v-on:click="$emit('previous-question')" v-if="!hidePreviousButton">{{translationMethod('test_question_back')}}</button>
      <a-tooltip v-if="isTimerRunningQuestion" placement="right">
        <template #title>{{translationMethod('execution_timer_info')}}</template>
          <button  type="button" name="button_save" class="Question__Actions--Button save" :disabled="isTimerRunningQuestion">{{translationMethod('continue_mode')}}</button>
        </a-tooltip>
      <button v-else type="button" name="button_save" class="Question__Actions--Button save" v-on:click="saveAnswer(null)"  :disabled="disableSubmitButton(question) || isSavingAnswer || (videos.length > 0 && !videoFinished) ">
        {{translationMethod('continue_mode')}}
        <img v-if="isSavingAnswer" src="../../assets/img/execution/ajax-loader.gif">
      </button>
    </div>
    <div class="Question__Order">
      <span v-if="question.question.order < 10">0{{question.question.order}}</span>
      <span v-if="question.question.order > 9">{{question.question.order}}</span>
    </div>
  </div>
</template>


<script>
import MultiSelection from './MultiSelection';
import Selection from './Selection';
import Ranking from './Ranking';
import RatinFaces from './RatinFaces';
import ImageChoice from './ImageChoice';
import MultipleImageChoice from './MultipleImageChoice';
import Sorting from './Sorting';
import TextQuestion from './TextQuestion';
import MultiText from './MultiText';
import Textarea from './Textarea';
import Number from './Number';
import DecimalNumber from './DecimalNumber';
import MultiNumber from './MultiNumber';
import Date from './Date';
import MatrixRefactor from './MatrixRefactor'
import ImageUrl from './ImageUrl';
import DividePoints from './DividePoints';
import Label from './Label';
import Maxdiff from './Maxdiff';
import GroupedQuestions from './GroupedQuestions'
import HeatMapQuestion from './HeatMapQuestion';
import SilentboxGroup from '../vue-silentbox/components/group';
import SilentboxGroupItem from '../vue-silentbox/components/item';
import overlay from '../vue-silentbox/components/overlay.vue';
import Icon from '../Icon';
import MediaViewer from '../MediaViewer';
import Timer from './Timer';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/zoom/zoom.scss";
SwiperCore.use([Navigation, Pagination,Scrollbar,Zoom, A11y]);

export default {
  name: 'Question',

  props: {
    question: {
      type: Object,
      default: null
    },
    hidePreviousButton: {
      type:Boolean,
      default:true,
    },
    isSavingAnswer:{
      type: Boolean,
      default: false,
    },
    enviroment:{
      type: String,
      default: "WAT"
    },
    groupedQuestion:{
      type:Boolean,
      default:false
    },
    currentQuestionOrder:{
      type:String,
      default: ''
    },
    currentQuestionTimer:{
      type:String,
      default: ''
    },
    currentQuestionIndex:{
      type: String,
      default: ''
    }
  },
  components:{
    MultiSelection,
    Selection,
    Ranking,
    RatinFaces,
    ImageChoice,
    MultipleImageChoice,
    Sorting,
    TextQuestion,
    MultiText,
    Textarea,
    Number,
    DecimalNumber,
    MultiNumber,
    Date,
    MatrixRefactor,
    ImageUrl,
    DividePoints,
    SilentboxGroup,
    SilentboxGroupItem,
    'silentbox-overlay': overlay,
    Icon,
    Label,
    Maxdiff,
    Swiper,
    SwiperSlide,
    MediaViewer,
    GroupedQuestions,
    HeatMapQuestion,
    Timer
  },
  data(){
    return{
      error:false,
      errorText:null,
      answers:[],
      images:[],
      videos:[],
      videoIsYoutube:false,
      imageDisplayType:"IMAGE_DISPLAY_MINIATURE",
      overlayVisibility: false,
      maxSizeImage:{
        visible: false,
        img:''
      },
      embedUrl:"",
      items:{
        current:0,
        total:0,
      },
      videoFinished: false,
      isTimerRunning: false,
      stopTimer: false
    }
  },
  methods:{
    translationMethod(text){
      return this.enviroment == "WAT" ? this.t(text) : this.$t(text)
    },
    setErrorText(txt){
      this.errorText = txt
      this.error = true
    },
    saveAnswer(event){
      this.stopTimer = true;
      if(this.question.question.matrixExtended){
        let answerForMatrixExtended = []
        for(let i in this.question.question.values){
          for(let i2 in this.question.question.radioOptionTexts){
            answerForMatrixExtended.push(this.question.question.values[i].id+"-"+i2+"-"+this.$refs.Matrix.answers[i][i2])
          }
        }
        this.handleAnswer({answer:{matrixVals:answerForMatrixExtended},question:this.question, answerValid: true})
      }
      if(this.question.question.type == 'MULTITEXT'){
        let multipleTexts = this.answers[event != null ? event.question.question.id : this.question.question.id].answer.multipleTexts
        for(let i = 0; i<multipleTexts.length;i++){
          if(multipleTexts[i] == undefined || multipleTexts[i] == null){
            multipleTexts.splice(i,1)
            i--
          } 
        }
        this.answers[event != null ? event.question.question.id : this.question.question.id].answer.multipleTexts = multipleTexts
      }
      this.$emit('save-answer', {answer:this.answers[event != null ? event.question.question.id : this.question.question.id]})
    },
    setTimerFinished(timerOption){
      if (this.imageDisplayType != null) this.closeOpenImages(this.imageDisplayType);
      switch (timerOption) {
        case 'killer':
        case null:
        case undefined:
          this.$emit('set-timer-killer');
          break;
        case 'next':
          this.saveAnswer(null);
          break;
        case 'timer':
        case 'timer-invisible':
        this.isTimerRunning = false;
            break;
        default:
          break;
      }
    },
    handleAnswer(e){
      let answers = this.answers
      this.answers = []
      answers[e.question.question.id] = e
      this.answers = answers
    },
    handleAnswerMaxdiff(e){
      this.handleAnswer(e)
      this.$emit('save-answer', {answer:this.answers[this.question.question.id]})
    },
    disableSubmitButton(q){
      if(q.question.type == "LABEL") return false
      if (this.answers[q.question.id] != undefined) return !this.answers[q.question.id].answerValid
      else return true
    },
    formatImages(images){
      this.items.total = images.length
      if (this.question.question.imageDisplayType != undefined && this.question.question.imageDisplayType != undefined) this.imageDisplayType = this.question.question.imageDisplayType
      for (let i in images) {
        this.images.push({src:images[i], id:i})
      }
    },
    formatYTvideo(video){
      this.videos = [{src:video, id:0}]
    },
    openImageMaxSize(img,i){
      this.maxSizeImage = {
        visible: true,
        img:img.src
      }
    },
    openImageOverlay(img,i){
      this.overlayVisibility = true
      this.embedUrl = img.src
      this.items.current = i
    },
    nextItem(){
      if(this.items.current + 1 == this.images.length) return
      this.items.current++
      this.embedUrl = this.images[this.items.current].src
    },
    prevItem(){
      if(this.items.current - 1 < 0) return
      this.items.current--
      this.embedUrl = this.images[this.items.current].src
    },
    encodeImageURL ( src ) {
      return encodeURI( src ).replace(/\(/g, '%28').replace(/\)/g, '%29');
    },
    isIE11(){
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
    checkQuestionIntegratedScript(){
      if(this.question.question.text){
        if(this.question.question.text.includes("<script")){
          let script = this.question.question.text.substr(this.question.question.text.indexOf("<script"))
          $("head").append(script);
        }
      }
    },
    videoThumbSrc(videoUrl) {
      // const { id } = parseVideoUrl(videoUrl);
      videoUrl = videoUrl.replace("wat-videos.s3.amazonaws.com","s3-eu-west-1.amazonaws.com/wat-thumbs")
      videoUrl = videoUrl.replace("wat-files-dev.s3.amazonaws.com","s3-eu-west-1.amazonaws.com/wat-thumbs")
      videoUrl = videoUrl.replace("experience-lab-videos","experience-labs-thumbs")
      videoUrl = videoUrl.replace("experience-lab-files","experience-labs-thumbs")
      videoUrl = videoUrl.replace("eperience-lab-files","experience-labs-thumbs")
      videoUrl = videoUrl.replace("eperience-lab-videos","experience-labs-thumbs")
      videoUrl = videoUrl.replace(".MOV","00001.png")
      videoUrl = videoUrl.replace(".mov","00001.png")
      videoUrl = videoUrl.replace(".mp4","00001.png")
      videoUrl = videoUrl.replace(".MP4","00001.png")
      return videoUrl;
    },
    youTubeVideoThumbSrc(url){
      if (url == undefined) {
        return ""
      }
      let id = url.split('v=')[1];
      let ampersandPosition = id.indexOf('&');
      if(ampersandPosition != -1) {
        id = id.substring(0, ampersandPosition);
      }

      return "https://img.youtube.com/vi/"+id+"/default.jpg"
    },
    closeImageMaxSize(){
      this.maxSizeImage.visible = false;
    },
    closeSilentBoxOverlay(){
      this.overlayVisibility = false;
    },
    closeOpenImages(display) {
      switch (display) {
        case 'IMAGE_DISPLAY_MINIATURE':
          if (this.$refs.group != undefined) this.$refs.group.closeSilentBoxOverlay();
          break;
        case 'IMAGE_DISPLAY_BIG':
        case 'IMAGE_DISPLAY_BIG_MINIATURE':
          this.closeSilentBoxOverlay();
          break;
        case 'IMAGE_DISPLAY_MAX_SIZE':
          this.closeImageMaxSize();
        default:
          break;
      }
    },
    handleVideoFinished() {
      this.videoFinished = true
    },
    scrollToTopOfQuestion(){
      this.$emit('scrollToTopOfQuestion')
    }
  },
  watch: {
    currentQuestionIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.stopTimer = false;
      }
    }
  },
  async mounted(){
    this.stopTimer = false;
    if(this.question.question.timerOption === 'timer' || this.question.question.timerOption === 'timer-invisible'){
      this.isTimerRunning = true
    }
   
    this.checkQuestionIntegratedScript()
    if (this.question.question.images) this.formatImages(this.question.question.images)
    if (this.question.question.video){
      this.formatYTvideo(this.question.question.video)
      if (this.question.question.video.includes("youtub"))
        this.videoIsYoutube = true
      else
        this.videoIsYoutube = false
    }
  },
  computed: {
    isTimerRunningQuestion() {
      return this.isTimerRunning
    },
    isTimerInvisible() {
      return this.question.question.timerOption === 'timer-invisible'
    }

  }
}
</script>

<style lang="scss">
@import 'swiper/swiper.scss';
@import "swiper/components/navigation/navigation.scss";
@import "swiper/components/pagination/pagination.scss";
@import "swiper/components/zoom/zoom.scss";
.swiper-container{
  height: 100%;
}

.swiper-slide{
  width: 100%!important;
}

#silentbox-overlay__close-button{
  width: 40px!important;
  height: 40px!important;
  .icon{
    width: 30px!important;
  }
}
.Question{
  .MediaItem{
    width: 170px;
    height: 120px;
  }
  button, input, select, textarea{
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  label{
    margin-bottom: 5px;
  }
  p{
    text-align: initial;
  }
  &__ImagesCr--MinImages{
    .swiper-pagination-bullets{
      display: none;
    }
  }
}
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');
.IE11{
  .Question{
    position: relative;
    padding:40px 60px;
    &.not-selected{
      opacity:0.05;
      .false-blur{
        position: absolute;
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        z-index:2;
        background: var(--contrast-color);
        opacity:0.3;
      }
      .Question__Header{
        span{
          visibility: hidden;
        }
      }
    }
    &.hidden{
      display: none;
    }
  }
}
.Question{
  width: 100%;
  padding: 5% 8%;
  background-color: rgba(255,255,255,0.9);
  color: black;
  position: relative;
  box-shadow: 0px 0px 56.64px 2.36px rgba(0, 0, 0, 0.5);
  &.not-selected{
    width:80%;
    background: var(--contrast-color);
    color: white;
    pointer-events: none;
    -webkit-filter: blur(9px);
    -moz-filter: blur(9px);
    -o-filter: blur(9px);
    -ms-filter: blur(9px);
    filter: blur(9px);
    .Question{
      &__Header{
        &--Num{
          background: transparent;
        }
      }
      &__Actions{
        &--Button{
          font-size: 0.588vw;
        }
      }
      &__Order{
        display:none;
      }
    }
  }
  &.hidden{
    display: none;
  }
  &__Header{
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    align-items: flex-start;
    margin-bottom:20px;
    p{
      font-size: 1.578vw;
      margin-bottom: 10px;
      max-width: 1050px;
      word-wrap: break-word;
      &.default{
        max-width: 650px;
      }
    }
    &--Num{
      font-family: 'Oswald', sans-serif;
      font-weight: bold;
      position: absolute;
      left: -2%;
      font-size: 2.556vw;
      border:1px solid white;
      color: white;
      padding: 4px 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
      box-shadow: -5px 6px 27.84px 1.16px rgba(0, 0, 0, 0.17);
    }
    &--Timer{
      font-size: 1.556vw;
      position: absolute;
      right: 2%;
      top: 6%;
    }
  }
  &__Type{
    font-size: 1.133vw;
    color: #666666;
    position: relative;
    z-index: 1;
    font-family: 'Nunito', sans-serif;
  }
  &__Actions{
    display: flex;
    &--Button{
      font-family: 'Bariol';
      font-weight: 600;
      font-size: 1.1vw;
      border-radius: 3px;
      border: none;
      padding: 4px 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color:white;
      margin: 20px 0;
      cursor: pointer;
      img{
        width: 18%;
      }
      &.save{
        background: var(--button-color);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 6px 10px;
      }
      &.previous{
        background: transparent;
        color: #5b5b5b;
        padding-left:0;
      }
      &:disabled{
        cursor:not-allowed;
        opacity: 0.6;
      }
    }
  }
  &__Order{
    position:absolute;
    font-weight: 900;
    font-size: 105px;
    line-height: 105px;
    bottom: 0;
    right: 2%;
    color: #f1edf8;
    font-family: 'Oswald', sans-serif;
  }
  &__Error{
    color:crimson;
    margin:10px 0 0 0;
  }
  &__ImagesCr{
    height: 350px;
    margin-bottom: 30px;
  }
  &__ImagesMax{
    &__Image{
      display: flex;
      justify-content: center;
      img{
        width: auto!important;
        height: auto!important;
        max-width: 100%;
      }
    }
  }
  &__ImagesBig,&__ImagesMax{
    width:100%;
    margin-bottom: 15px;
    &__Flip{
      position:absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background: rgba(0,0,0, .75);
      transition: opacity 250ms ease;
      opacity:0;
      cursor:pointer;
      top:0;
    }
    &__Image{
      width:100%;
      height:auto;
      margin-top: 5px;
      position: relative;
      img{
        width:100%;
        height:auto;
      }
      &:hover{
        .Question__ImagesBig__Flip,.Question__ImagesMax__Flip{
          opacity: 1;
        }
      }
    }
  }
  &__ImagesMin{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    &__Image{
      width: 150px;
      min-height: 100px;
      background: white;
      position: relative;
      border: 1px solid lightgray;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
      }
      &:hover{
        .Question__ImagesMin__Flip{
          opacity: 1;
        }
      }
    }
    &__Flip{
      position:absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background: rgba(0,0,0, .75);
      transition: opacity 250ms ease;
      opacity:0;
      cursor:pointer;
    }
  }

  &__ImageMaxSizeViewer{
    position:fixed;
    background: rgba(0,0,0,0.9);
    top:0;
    right:0;
    width:100%;
    height: 100%;
    padding: 30px 30px 30px 30px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    overflow-y:auto;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: var(--main-color);
    }
    &--close{
      display: flex;
      position: absolute;
      top: -13px;
      right: -20px;
    }
    &--content{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column;
      button{
        font-family: 'Bariol';
        font-weight: 600;
        font-size: 1.1vw;
        border-radius: 3px;
        border: none;
        padding: 4px 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color:white;
        margin-bottom: 20px;
        margin-top: -20px;
        background: var(--button-color);
      }
      p{
        background: white;
        width:20px;
        height: 2px;
        background: #fff;
        margin-left: -20px;
        cursor: pointer;

        &:first-of-type{
          transform: rotate(45deg);
        }
        &:last-of-type{
          transform: rotate(-45deg);
        }
      }
      img{
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
  }
  &--Description{
    margin-top: -18px;
    color: #8c8c8c;
    margin-bottom: 15px;
    font-size: 1.3vw;
  }
  .sortingDragImage{
    position: absolute;
    right: 3%;
    width: 4%;
  }
  @media only screen and ( max-width: 769px ) {
    padding: 10px;
    animation: fadeIn 0.5s;
    &.not-selected{
      display: none;
      position: absolute;
      visibility: hidden;
      width:90%;
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
      filter: blur(0px);
    }
    &.hidden{
      display:none;
    }
    &__Header{
      flex-direction: column;
      align-items: flex-start;
      p{
        font-size: 21px;
      }
      &--Num{
        position:inherit;
        font-size: 7.801vw;
        // padding: 1.5% 3.5%;
        margin-bottom: 20px;
      }
      &--Timer{
        font-size: 5.801vw;
        right: 3%;
        top: 3%;
        height: 20%;
      }
    }
    &__Order{
      display: none;
    }
    &__Type{
      font-size:18px;
    }
    &__Actions{
      display: flex;
      justify-content: space-between;
      &--Button{
        font-size: 17px;
        font-weight: 500;
        margin:20px 0 0 0;
      }
    }
    &__ImagesMin{
      div{
        width: 100%;
      }
    }
    &--Description{
      font-size: 18px;
    }
    .sortingDragImage{
      display: none;
    }
  }
}
@keyframes fadeIn{
  from{opacity:0;}
  to{opacity:1;}
}
.MediaGroup {
  display: flex;
  flex-wrap: wrap;
  // & /deep/ #silentbox-overlay {
  //   z-index: 9999;
  // }
}

.MediaItem {
  display: block;
  width: 85px;
  height: 60px;
  margin-right: .5em;
  margin-bottom: .5em;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px #ddd;
  position: relative;
  display: flex;

  @media screen and ( max-width: 768px ) {
    width: 48%;
    height: auto;
    padding-top: 48%;
    margin: 1%;
  }

  &__Overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
  }

  &__TypeIcon {
    margin: auto;
    color: #fff;
  }

  &:hover {
    box-shadow: 0 0 10px #bbb;

    .MediaItem__Overlay {
      opacity: 1;
      transition: all ease-in .3s;
    }
  }
}

button, input, select, textarea{
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.hidden-timer {
    visibility: hidden;
  }
</style>
