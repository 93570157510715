<template lang="html">
  <div class="ImageUrl">
    <div class="ImageUrl__Upload" v-on:click="openImagePicker()">
      <div class="ImageUrl__Upload--Icon">
        <i class="far fa-image"></i>
      </div>
      <div class="ImageUrl__Upload--Title">
        {{$parent.translationMethod('question_upload_image')}}
      </div>
      <div v-if="answer != undefined && !isImageUploading" class="ImageUrl__Upload--Image" :style="{ backgroundImage: 'url(\'' + answer + '\')' }">

      </div>
    </div>
    <input type="file" id="file" ref="questionFile"
      @change="uploadImage">
  </div>
</template>

<script>
import Vue from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import store from '@/store'
export default {
  name: 'ImageUrl',

  props: {
    question:{
      type: Object,
      default: null
    },
    uploadUrl: {
      type: String,
      default: null,
    },
    uploadHeaders: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      answer:undefined,
      isImageUploading:false
    }
  },
  methods: {
    async uploadImage(){
      this.sendAnswers("", false)
      this.isImageUploading = true
      let file = this.$refs.questionFile.files[0]
      let formData = new FormData();
      formData.append('file',file)
      await store.dispatch('UPLOAD_IMAGE_URL',formData)
       .then((data) => {
         this.answer = data.data.path
         this.sendAnswers(data.data.path, true)
         this.isImageUploading = false
       })
    },
    openImagePicker(){
      this.$refs.questionFile.click()
    },
    sendAnswers(answer, answerValid){
      this.$emit('send-answers', {answer:{text:answer},question:this.question, answerValid:answerValid})
    }
  },
  async mounted(){
    if (this.question.answer) {
      this.answer = this.question.answer.text
      this.sendAnswers(this.answer, true)
    }
    if(this.question.question.textEmpty){
      this.sendAnswers("", true)
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

.ImageUrl{
  &__Upload{
    background: #f8f8f8;
    color: #d8d8d8;
    height: 114px;
    width: 35%;
    max-width: 181px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    position: relative;
    &--Icon{
      font-size: 30px;
    }
    &--Title{
      font-size: 17px;
      font-family: 'Oswald', sans-serif;
    }
    &--Image{
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100%;
      width: 100%;
      top: 0;
      left:0;
      position: absolute;
    }
  }
  input{
    visibility: hidden;
  }
}
</style>
